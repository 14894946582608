@import 'bootstrap/scss/functions';

$primary:  #22C0C0;
$success:  #51e9a2;
$danger:   #BB593C;
$orange:   #e6704c;

$min-contrast-ratio:   2.2;

$border-radius:        .6rem !default;
$border-radius-sm:     .7rem !default;
$border-radius-lg:     .8rem !default;
$border-radius-pill:   50rem !default;


// 3. Include remainder of required Bootstrap stylesheets
@import "bootstrap/scss/variables";
@import "bootstrap/scss/variables-dark";
@import "bootstrap/scss/maps";

$input-focus-box-shadow: 0px 0px 2px 2px #888888;

$component-active-color:      $white;
$component-active-bg:         $primary;


// customing button
$btn-line-height: 2;
$btn-padding-x: 0.8rem;

$input-btn-normal-width:         .25rem;
$input-btn-normal-color-opacity: .25;
$input-btn-normal-color:         rgba($component-active-bg, $input-btn-normal-color-opacity);
$input-btn-normal-blur:          0;
$input-btn-normal-box-shadow:    0 0 $input-btn-normal-blur $input-btn-normal-width $input-btn-normal-color;

$input-group-addon-color:        $light;
$input-group-addon-bg:           $dark;

$btn-normal-box-shadow: $input-btn-normal-box-shadow;

@import "bootstrap/scss/mixins";
@import "bootstrap/scss/root";