.pnp-page {
  margin: 30px 20px 30px 20px;

  .pnp-title {
    padding: 6px 0;
    flex-grow: 1;
    font-weight: bold;
  }

  .pnp-content {
    padding-bottom: 20px;
    text-align:  justify;
  }
}