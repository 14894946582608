.troubleshoot-page {
  margin: 30px 20px;

  .tab-bar {
    display: flex;
    gap: 0.5rem;
    overflow-x: scroll;
    margin-bottom: 1rem;
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for Firefox */
    scrollbar-width: none;

    /* Hide scrollbar for IE and Edge */
    -ms-overflow-style: none;

    .capsule {
      padding: 0.25rem 1rem;
      border-radius: 50px;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      -o-border-radius: 50px;
      cursor: pointer;
      white-space: nowrap;
      color: #b0b0b0;
      border: 0.1rem solid #eeeeee;
      font-weight: 500;
    }

    .selected {
      background-color: rgba(2, 162, 245, 0.1);
      color: rgba(2, 162, 245, 1);
      border: 0.1rem solid transparent;
    }
  }

  .troubleshoot-section {
    margin-bottom: 1rem;
    .title {
      // font-size: 1.25rem;
      margin-top: 3rem;
      margin-bottom: 1rem;
      font-weight: 600;
    }

    .troubleshoot-item {
      .upper-content {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        cursor: pointer;
        padding: 6px 0;

        .icon {
          margin-left: 20px;
          color: #02a2f5;
          transition: transform 0.5s;
          align-self: center;

          &.icon-open {
            transform: rotate(-180deg);
            -webkit-transform: rotate(-180deg);
            -moz-transform: rotate(-180deg);
            -ms-transform: rotate(-180deg);
            -o-transform: rotate(-180deg);
          }
        }

        .title {
          font-weight: 700;
        }

        .subtitle {
          flex-grow: 1;
          font-weight: 500;
          white-space: pre-line;
        }
      }

      .paragraph {
        font-size: 1rem;
        margin: 0;
      }

      .potcause-container {
        display: flex;

        .left-content {
          min-width: 1.5rem;
        }
      }
    }
  }
}

.mr-1 {
  margin-right: 1rem;
}
