.faq-page {
  margin: 30px 20px;

  .faq-question {
    display: flex;
    align-items: center;
    padding: 6px 0;
    cursor: pointer;

    .text {
      flex-grow: 1;
      font-weight: 500;
    }

    .icon {
      margin-left: 20px;
      color: #02a2f5;
      transform: rotate(90deg);
      transition: transform 0.5s;

      &.icon-open {
        transform: rotate(-90deg);
      }
    }
  }

  .faq-answer {
    padding-bottom: 10px;
  }
}
