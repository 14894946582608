.subtitle {
  font-size: 1.25rem;
}

.title {
  font-size: 2rem;
}

.vignette {
  background: linear-gradient(to top, rgba(0, 0, 0, 0.9), transparent);
}
