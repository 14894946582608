.tou-page {
  margin: 30px 30px 30px 20px;

  .tou-title {
    padding: 6px 0;
    flex-grow: 1;
    font-weight: bold;
  }

  .tou-content {
    padding-bottom: 20px;
    text-align:  justify;
  }
}