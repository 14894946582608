@use 'sass:map';
@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'variables';

// 4. Include any optional Bootstrap CSS as needed
@import 'bootstrap/scss/utilities';
@import 'bootstrap/scss/reboot';
@import 'bootstrap/scss/type';
@import 'bootstrap/scss/images';
@import 'bootstrap/scss/containers';
@import 'bootstrap/scss/grid';
@import 'bootstrap/scss/helpers';

// 5. Optionally include utilities API last to generate classes based on the Sass map in `_utilities.scss`
@import 'bootstrap/scss/utilities/api';

@import 'bootstrap/scss/forms';
@import 'bootstrap/scss/tables';
@import 'bootstrap/scss/carousel';
@import 'bootstrap/scss/transitions';
@import 'bootstrap/scss/spinners';

// @import 'color.scss';
// @import 'button.scss';
// @import 'modal.scss';
// @import 'form.scss';
// @import 'box.scss';
// @import 'animate.scss';

@font-face {
  font-family: 'Inter';
  src: url('../../public/assets/fonts/Inter/Inter-VariableFont_slnt,wght.ttf');
}

@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

.poppins-thin {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: normal;
}

.poppins-regular {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: 'Poppins', sans-serif;
  font-weight: 900;
  font-style: italic;
}

body {
  font-family: 'Inter';
  background-color: get-color('light');
  color: get-color('dark');
}

.navbar-top {
  width: 100%;
  padding: 49px 60px 5px 60px;
  display: flex;
  align-items: baseline;

  .navbar-top-left {
    text-align: left;
  }
  .navbar-top-right {
    text-align: right;
    flex-grow: 1;
  }

  .logo {
    max-height: 57.44px;
  }

  nav {
    align-content: center;

    > a {
      color: rgb(209, 209, 209);
      padding: 5px 30px;
      text-decoration: none;

      &:hover {
        color: rgb(238, 238, 238);
      }
    }
  }
}

h1 {
  font-weight: 600;
  font-size: 60px;
}

h4 {
  font-size: 30px;
  font-weight: 400;
}

h5 {
  font-size: 26px;
  font-weight: 300;
}

.splashscreen {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgb(209, 209, 209);
  z-index: 3000;

  .splashscreen-content {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

.text-loading {
  position: relative;
  color: rgba(0, 0, 0, 0);
  min-height: 1em;
  overflow-x: hidden;
  background-color: rgba(var(--bs-body-color-rgb), 0.1);

  &::before {
    content: '\00a0';
    display: block;
    position: absolute;
    width: 140px;
    height: 100%;
    background-image: linear-gradient(
      to right,
      rgba(255, 255, 255, 0),
      rgba(255, 255, 255, 0.12),
      rgba(255, 255, 255, 0)
    );
    animation: loadingMove 1s infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 3s;
  }

  @keyframes loadingMove {
    0% {
      left: -140px;
    }
    100% {
      left: 100%;
    }
  }
}
